import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import LearnMore from "../components/learn-more"
import Seo from "../components/seo"

import LearnBackground1 from "../images/about-bg1.jpg"
import LearnImage1 from "../images/about-img1.jpg"

import LearnBackground2 from "../images/about-bg2.jpg"
import LearnImage2 from "../images/about-img2.jpg"

import LearnBackground3 from "../images/about-bg3.jpg"
import LearnImage3 from "../images/about-img3.jpg"

import parse from "html-react-parser"

const About = ({ location }) => {
  const { about } = useStaticQuery(graphql`
    query aboutQuery {
      about {
        title
        headerImagePeoplePage {
          url
        }
        description
        imageOneBottomImages {
          url
        } 
        imageTwoofBottomImages {
          url
        } 
        imageThreeofBottomImages {
          url
        } 
        people
        peopleBackgroundImage {
          url
        }
        peopleForegroundimage {
          url
        }
        peopleCTA {
          href
        } 
        places
        placesBackgroundImage {
          url
        }
        placesForegroundImage {
          url
        }
        placesCTA {
          href
        }
        company
        companyBackgroundImage {
          url
        }
        companyForegroundImage {
          url
        }
        companyCTA {
          href
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="About" location={location} />
      <img
        className="cha-about__header-img"
        src={about.headerImagePeoplePage ? about.headerImagePeoplePage.url : "../images/about-us.jpg"}
        alt="About us"
      />
      <div className="cha-about__container">
        <h1 className="h1-header">Our Story</h1>
        <span className="subhead">
          {about.title}
        </span>
        <div className="cha-about__container__description">
          <p className="body1">
            {parse(about.description)}
          </p>

        </div>
      </div>

      <LearnMore
        backgroundImage={about.peopleBackgroundImage ? about.peopleBackgroundImage.url : LearnBackground1}
        image={about.peopleForegroundimage ? about.peopleForegroundimage.url : LearnImage1}
        title={"People"}
        description={about.people}
        to={about.peopleCTA ? about.peopleCTA.href : "/"}
        reverse={false}
      />

      <LearnMore
        backgroundImage={about.placesBackgroundImage ? about.placesBackgroundImage.url : LearnBackground2}
        image={about.placesForegroundImage ? about.placesForegroundImage.url : LearnImage2}
        title={"Places"}
        description={about.places}
        to={about.placesCTA ? about.placesCTA.href : "/"}
        reverse={true}
      />

      <LearnMore
        backgroundImage={about.companyBackgroundImage ? about.companyBackgroundImage.url : LearnBackground3}
        image={about.companyForegroundImage ? about.companyForegroundImage.url : LearnImage3}
        title={"Company"}
        description={about.company}
        to={about.companyCTA ? about.companyCTA.href : "/"}
        reverse={false}
      />

      <div className="cha-about__container__footer">
        <div className="cha-about__container__footer__container">
          <img
            className="cha-about__container__footer__img"
            src={about.imageOneBottomImages ? about.imageOneBottomImages.url : "../images/about-f1.jpg"}
            alt="About us"
          />
        </div>
        <div className="cha-about__container__footer__container">
          <img
            className="cha-about__container__footer__img"
            src={about.imageTwoofBottomImages ? about.imageTwoofBottomImages.url : "../images/about-f2.jpg"}
            alt="About us"
          />
        </div>
        <div className="cha-about__container__footer__container">
          <img
            className="cha-about__container__footer__img"
            src={about.imageThreeofBottomImages ? about.imageThreeofBottomImages.url : "../images/about-f3.jpg"}
            alt="About us"
          />
        </div>
      </div>
    </Layout>
  )
}

export default About
